<template>
  <div>
    <div>
      <Header></Header>
      <div class="p-8 mx-auto max-w-7xl">
        <slot></slot>
      </div>
    </div>
    <UNotifications />
  </div>
</template>
<script setup lang="ts">

</script>
<style lang="scss">
.dark {
  background: rgb(var(--color-gray-900) / 1);
  --color-primary-DEFAULT: var(--color-gray-900);
  --ui-background: var(--color-gray-900);
  --ui-foreground: var(--color-gray-200);
}
</style>